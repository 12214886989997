import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Kids = () =>{
  const collage = [
    "/images/kids/kid1.webp" ,
   "/images/kids/kid2.webp" ,
    "/images/kids/kid4.webp" ,
   "/images/kids/kid55.webp" ,
    "/images/kids/kid56.webp" ,
    "/images/kids/kid5.webp" ,
    

  "/images/kids/kid23.webp" ,
    
    "/images/kids/kid43.webp", 
    "/images/kids/kid44.webp" ,
   
     "/images/kids/kid49.webp" ,
    "/images/kids/kid50.webp" ,
   "/images/kids/kid52.webp" ,
   "/images/kids/kid53.webp" ,
   "/images/kids/kid54.webp" ,
    "/images/kids/kid50.webp" ,
  ]

      return(
          <>
        
              <Portfolio collage={collage}  title="KIDS' MOMENTS" text="From giggles to curious glances, I specialize in capturing the joy and innocence of childhood moments. Let me freeze-frame those precious smiles and playful adventures that you'll cherish forever."






/>
              <Slider/>
              </>
      )
  }
  

export default Kids;