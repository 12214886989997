import React from 'react';
import LazyLoad from 'react-lazyload';
import style from  "./LazyImg.module.css"
const LazyImage = ({ src, alt, height = 200, offset = 100 }) => (
  <LazyLoad height={height} offset={offset}  placeholder={<img src="path_to_placeholder.jpg" alt="Loading" />}>
    <img src={src} alt={alt}  />
  </LazyLoad>
);

export default LazyImage;
