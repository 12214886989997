import React, { useEffect, useRef } from 'react';
import style from "./Video.module.css"

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

const Video = () => {
    const videoRefs = useRef([]);

    useEffect(() => {
        AOS.init();
    }, []);

    const handleSlideChange = (swiper) => {
        // Pause all videos when the slide changes
        videoRefs.current.forEach((video) => {
            video.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        });
    };

    return (
        <div data-aos="fade-up" className='videoIframe container'>
            <Swiper
                pagination={{
                    type: 'fraction',
                }}
                navigation={true}
                onSlideChange={handleSlideChange}
                modules={[Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <iframe
                        ref={(el) => (videoRefs.current[0] = el)}
                        src="https://www.youtube.com/embed/yjjRHdOcznU?enablejsapi=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </SwiperSlide>
                <SwiperSlide>
                    <iframe
                        ref={(el) => (videoRefs.current[1] = el)}
                        src="https://www.youtube.com/embed/srGZyAAxnU8?enablejsapi=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </SwiperSlide>
            </Swiper>
        </div>
    );
}

export default Video;
