import './App.css';
import RouteConfig from './router';
import Nav from './layout/header/Header';
import Footer from './layout/footer/Footer';

function App() {
  return (
        <>
        
        {/* <div className='emailSticky'>
        <a href="mailto:medirushitiphoto@gmail.com"><img src="/images/email.png"/></a>
        </div> */}
        {/* <div className="elfsight-app-9c166c3a-fd36-4381-bf70-fddfe013491a" data-elfsight-app-lazy></div> */}
        <Nav/>
        <RouteConfig/>
        <Footer/>
        </>
  );
}

export default App;
