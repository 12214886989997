import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Cars = () =>{
  const collage = [
    "/images/cars/car1.webp",
    "/images/cars/car2.webp",
    "/images/cars/car3.webp",
    "/images/cars/car4.webp",
    "/images/cars/car5.webp",
    "/images/cars/car6.webp",
    "/images/cars/car7.webp",
    "/images/cars/car8.webp",
    "/images/cars/car9.webp",
    "/images/cars/car10.webp",
    "/images/cars/car11.webp",
    "/images/cars/car12.webp",
    "/images/cars/car13.webp",
    "/images/cars/car14.webp",
    "/images/cars/car15.webp",
    "/images/cars/car16.webp",
    "/images/cars/car17.webp",
    "/images/cars/car18.webp",
    "/images/cars/car19.webp"
];

      return(
          <>
        
              <Portfolio collage={collage}  title="Car Shows & More" text="Capturing the essence of every curve and detail, I specialize in creating stunning photographs that showcase the beauty and power of your cherished vehicle. Let's bring your car's story to life through the lens."/>
              <Slider/>
              </>
      )
  }
  

export default Cars;