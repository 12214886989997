import React from 'react';
import styles from './ImageCollage.module.css';


const ImageCollage = (props) => {
    
  return (
    <main className={`container ${styles.main}`} data-aos="fade-up">
      <div >
        <div className={styles.grid}>
          {props.images.map((src, index) => (
            <div key={index} className={styles.imageWrapper}>
              <img src={src} alt={`Collage image ${index + 1}`} loading="lazy" className={styles.image} />
              <div className={styles.overlay}></div>
            </div>
          ))}
        </div>
      </div>
    </main>
  );
};

export default ImageCollage;
