import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import style from "./Contact.module.css"
const Contact = () =>{
  useEffect(() => {
    AOS.init();
  }, []);
    return(
 
       <div className="container">

           <div className={style.contactDis}>
           <div data-aos="fade-up">
            <div>
                  <h2>GET IN TOUCH</h2>
            </div>
            </div>
            <div data-aos="fade-up">
            <div>
                <img src="/images/zam.jpeg" alt="gl"/>
                </div>
          
      </div>
      <div className={` ${style.contactBtn}`}>
        <p>MUHAMED RUSHITI IS A PROFESSIONAL PHOTOGRAPHER + CREATIVE DIRECTOR BASED IN PRISTINA, KOSOVO.</p>
        <div>
        <a href="mailto:medirushitiphoto@gmail.com"><img src="/images/email.png"/></a>
        
        <a href="tel:+38343972387"><img src="/images/phone.png"/></a>
        </div>
        
      </div>
        </div>
       </div>
     
    )
}

export default Contact