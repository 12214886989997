import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Comercial = () =>{
  const collage = [
    "/images/comercial/pan1.webp",
    "/images/comercial/pan2.webp",
    "/images/comercial/pan3.webp",
    "/images/comercial/pan4.webp",
    "/images/comercial/pan5.webp",
    "/images/comercial/pan6.webp",
    "/images/comercial/pan7.webp",
    "/images/comercial/pan8.webp",
    "/images/comercial/pan9.webp",
    "/images/comercial/pan10.webp",
    "/images/comercial/pan11.webp",
    "/images/comercial/pan12.webp",
    "/images/comercial/pan13.webp",
    "/images/comercial/pan14.webp",
    "/images/comercial/pan15.webp",
    "/images/comercial/pan16.webp",
    "/images/comercial/pan17.webp",
    "/images/comercial/pan18.webp",
    "/images/comercial/pan19.webp",
    "/images/comercial/pan20.webp",
    "/images/comercial/pan21.webp",
    "/images/comercial/pan22.webp",
    "/images/comercial/pan23.webp",
    "/images/comercial/pan24.webp",
    "/images/comercial/pan25.webp",
    "/images/comercial/pan26.webp",
    "/images/comercial/pan27.webp",
    "/images/comercial/pan28.webp",
    "/images/comercial/pan29.webp",
    "/images/comercial/pan30.webp",
    "/images/comercial/pan31.webp",
    "/images/comercial/pan32.webp",
    "/images/comercial/pan33.webp",
    "/images/comercial/pan34.webp",
    "/images/comercial/pan35.webp",
    "/images/comercial/pan36.webp",
    "/images/comercial/pan37.webp",
    "/images/comercial/pan38.webp",
    "/images/comercial/pan39.webp",
    "/images/comercial/pan40.webp",
    "/images/comercial/pan41.webp",
    "/images/comercial/pan42.webp",
    "/images/comercial/pan43.webp",
    "/images/comercial/pan44.webp",
    "/images/comercial/pan45.webp",
    "/images/comercial/pan46.webp",
    "/images/comercial/pan47.webp",
    "/images/comercial/pan48.webp",
    "/images/comercial/pan49.webp",
    "/images/comercial/pan50.webp",
    "/images/comercial/pan51.webp",
    "/images/comercial/pan52.webp",
    "/images/comercial/pan53.webp",
    "/images/comercial/pan54.webp",
    "/images/comercial/pan55.webp",
    "/images/comercial/pan56.webp",
    "/images/comercial/pan57.webp",
    "/images/comercial/pan58.webp",
    "/images/comercial/pan59.webp",
    "/images/comercial/pan60.webp",
    "/images/comercial/pan61.webp",
    "/images/comercial/pan62.webp",
    "/images/comercial/pan63.webp"
];


      return(
          <>
        
              <Portfolio collage={collage}  title="COMERCIAL WORK" text="Explore our portfolio of commercial photography, featuring high-impact images that elevate brands and products with professional, eye-catching visuals."/>
              <Slider/>
              </>
      )
  }
  

export default Comercial;