import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Spaces = () =>{
  const collage = [
    "/images/spaces/event1.webp",
    "/images/spaces/event2.webp",
    "/images/spaces/event3.webp",
    "/images/spaces/event4.webp",
    "/images/spaces/event5.webp",
    "/images/spaces/event6.webp",
    "/images/spaces/event7.webp",
    "/images/spaces/event8.webp",
    "/images/spaces/event9.webp",
    "/images/spaces/event10.webp",
    "/images/spaces/event11.webp",
    "/images/spaces/event13.webp",
    "/images/spaces/event14.webp",
    "/images/spaces/event18.webp",
    "/images/spaces/event19.webp",
    "/images/spaces/event20.webp",
    "/images/spaces/event21.webp",
    "/images/spaces/event24.webp"
];


      return(
          <>
        
              <Portfolio collage={collage}  title="Hotel & Dining Spaces" text="Discover elegant images of hotel and dining interiors, showcasing the refined design and inviting ambiance of these spaces."/>
              <Slider/>
              </>
      )
  }
  

export default Spaces;