import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Maternity = () =>{
  const collage = [
    "/images/maternity/mat1.webp",
    "/images/maternity/mat2.webp",
    "/images/maternity/mat3.webp",
    "/images/maternity/mat4.webp",
    "/images/maternity/mat5.webp",
    "/images/maternity/mat6.webp"
];


      return(
          <>
        
              <Portfolio collage={collage}  title="Maternity Moments" text="Celebrate the beauty of pregnancy with our heartfelt maternity photography, capturing the joy and anticipation of expecting mothers in elegant and touching portraits."/>
              <Slider/>
              </>
      )
  }
  

export default Maternity;