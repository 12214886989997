import React from "react";
import style from "./ChangeBanner.module.css"
import LazyImage from "../loadImg/LazyImg";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
const ChangeBanner = () =>{
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
    const banner = [
        {
            image:"/images/weddings/wedp5.webp",
            name:"WEDDINGS",
            link:"/weddings",
            position:'flip-left'
          },
          {
            image:"/images/modeling/model35.webp",
            name:"MODELING ELEGANCE"
            ,
            link:"/lifestyle"
            ,
            position:'flip-left'
          },
          {
            image:"/images/eng.jpg",
            name:"ENGAGEMENT",
            link:"/engagement",
            position:'flip-right'
          },
          {
            image:"/images/kids/kid2.webp",
            name:"KIDS' MOMENTS"    ,
            link:"/kids",
            position:'flip-right'
          },
    ]
    return(
      <div className={style.bannerBck}>
         
            <div className={` ${style.bannerDis}`}>
                {banner.map((item,index)=>{
                    return(
                      <div data-aos={item.position} key={index} className={style.animationBanner}>
                        <Link to={item.link}   onClick={() => {
                            window.scrollTo(0, 0);
                      
                        }}>
                              <div className={style.overlay}></div>
                          
                             <LazyImage src={item.image} alt="Description1" />
                             <p>{item.name}</p>
                        </Link>
                        </div>
                    )
                })}
            </div>
            </div>
    )
}

export default ChangeBanner;