import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/home/Home";
import Contact from "../pages/contact/Contact";
import Collage from "../components/collage/Collage";
import Weddings from "../pages/weddings/Weddings";
import Lifestyle from "../pages/lifestyle/Lifestyle";
import Kids from "../pages/kids/Kids";
import Engagments from "../pages/engagments/Engagments";
import Cars from "../pages/cars/Cars";
import AboutMe from "../pages/about/About";
import Spaces from "../pages/spaces/Spaces";
import NightLife from "../pages/nightlife/NightLife";
import Comercial from "../pages/comercial/Comercial";
import Maternity from "../pages/maternity/Maternoty";

const RouteConfig = (props) =>{
    return(
        <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/portfolio" element={<Collage/>} />
        <Route path="/weddings" element={<Weddings/>} />
        <Route path="/lifestyle" element={<Lifestyle/>} />
        <Route path="/kids" element={<Kids/>} />
        <Route path="/engagement" element={<Engagments/>} />
        <Route path="/cars" element={<Cars/>} />
        <Route path="/spaces" element={<Spaces/>} />
        <Route path="/nightlife" element={<NightLife/>} />
        <Route path="/comercail" element={<Comercial/>} />
        <Route path="/maternity" element={<Maternity/>} />
        <Route path="/about" element={<AboutMe/>} />
        </Routes>
    )
}
 
export default RouteConfig;