import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import style from "./Category.module.css"
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";

const Category = (props) =>{
  useEffect(() => {
    AOS.init();
  }, []);
    return(
        
      <div data-aos="fade-up">
        <Link to={props.link}  onClick={() => {
              window.scrollTo(0, 0);
        
          }}>
            <LazyLoad height={200} offset={100}  className={`${style.category} ${style[props.color]} ${style[props.position]}`}>
            <img src={props.image}/>
            <h2>{props.category}</h2>
        </LazyLoad>
        </Link>
        </div>
    )
}

export default Category;