import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const NightLife = () =>{
  const collage = [
    "/images/nightlife/night1.webp",
    "/images/nightlife/night2.webp",
    "/images/nightlife/night3.webp",
    "/images/nightlife/night4.webp",
    "/images/nightlife/night5.webp",
    "/images/nightlife/night6.webp",
    "/images/nightlife/night7.webp",
    "/images/nightlife/night8.webp",
    "/images/nightlife/night9.webp",
    "/images/nightlife/night10.webp",
    "/images/nightlife/night11.webp",
    "/images/nightlife/night12.webp",
    "/images/nightlife/night13.webp",
    "/images/nightlife/night14.webp",
    "/images/nightlife/night15.webp",
    "/images/nightlife/night16.webp",
    "/images/nightlife/night17.webp",
    "/images/nightlife/night18.webp",
    "/images/nightlife/night19.webp",
    "/images/nightlife/night20.webp",
    "/images/nightlife/night21.webp",
    "/images/nightlife/night22.webp",
    "/images/nightlife/night23.webp"
];


      return(
          <>
        
              <Portfolio collage={collage}  title="Nightlife Hotspots" text="Dive into vibrant nightlife with our collection of dynamic photos capturing the energy and excitement of evening scenes, from lively clubs to stylish bars."/>
              <Slider/>
              </>
      )
  }
  

export default NightLife;