import React from "react";
import style from "./Footer.module.css"
import { Link } from "react-router-dom";

const Footer = () =>{
    const handleClick = () => {

          window.scrollTo(0, 0);;
      };
    return(
        <div className={` ${style.footer}`}>
            <div>
            <div className={style.footerItem}>
           <Link  to="/" onClick={handleClick}>home </Link>
            <Link to="/portfolio" onClick={handleClick}>portfolio</Link>
        
            <Link to="/contact" onClick={handleClick}>contact </Link>
            <Link to="/about"onClick={handleClick}>about </Link>
            </div>
                <p>All content Copyright © 2024 Medi Rushiti Photo</p>
            </div>
            <div className={style.footerIcons}>
                <a href="https://www.instagram.com/medirushiti/" target="_blank" rel="noreferrer"><img src="/images/iyou.png" alt="icon"/></a>
                <a href="mailto:medirushitiphoto@gmail.com" target="_blank" rel="noreferrer"><img src="/images/emailf.png" alt="icon"/></a>
            </div>
        </div>
    )
}

export default Footer