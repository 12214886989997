import React, { useEffect } from 'react';
import style from "./Home.module.css"

import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { animateScroll } from "react-scroll";
import Category from '../../components/category/Category';
import Slider from '../../components/slider/slider';
import ChangeBanner from '../../components/changeBanner/ChangeBanner';
import { Link } from 'react-router-dom';
import Video from '../../components/video/Video';
import ImageCollage from '../../components/imageCollage/ImageCollage';
const Home = () =>{
  useEffect(() => {
    AOS.init();
  }, []);
  const comercial = [
    {
      image:"/images/weddings/wedp27.webp",
      name:"Wedding Moments",
      link:"/weddings"
    },
    {
      image:"/images/modeling/model34.webp",
      name:"Fashion Creations",
      link:"/lifestyle"
    },
    {
      image:"/images/nightlife/night13.webp",
        name:"Nightlife Hotspots",
      link:"/nightlife"
    },
    {
      image:"/images/modeling/model23.webp",

          name:"Fashion Modeling",
      link:"/lifestyle"
    },
    {
      image:"/images/kids/kid53.webp",
      name:"Birthday Celebrations",
      link:"/kids"
    },
    {
      image:"/images/cars/car3.webp",
      name:"Car Shows & More",
      link:"/cars"
    },
    {
      image:"/images/maternity/mat3.webp",
      name:"Maternity Moments",
      link:"/maternity"
    },
    {
      image:"/images/spaces/event10.webp",
      name:"Hotel & Dining Spaces",
      link:"/spaces"
    },
  ]
  const images = [
    
    '/images/stormz.webp',

    
  '/images/www.webp',
  
  '/images/mall.webp',
  
  '/images/llambo.webp',


  '/images/comercial/pan4.webp',
  '/images/stoo.webp',

  '/images/ezani.webp',
  
  "/images/jc.webp",
  // '/images/cars/car5.webp',
  "/images/big.webp",

  '/images/maa.webp',
  '/images/modeling/model34.webp',
  
 
];

    return(
        <div className={style.homepagePos}>
         <ImageCollage images={images}/>
          {/* <Swiper
        pagination={{
          type: 'fraction',
        }}
       
        navigation={true}
        loop={true}
        autoplay={{
            delay: 5000, // Set the autoplay delay to 5000ms (5 seconds)
          }}
        modules={[Pagination, Navigation,Autoplay]}
        className={`mySwiper bannerSwiper ${style.bannerSwiper}`}
      >
        <SwiperSlide className={style.bannerSwiperImage}><img src='/images/work.webp'/></SwiperSlide>
        <SwiperSlide className={style.bannerSwiperImage}><img src='/images/wed.jpg'/></SwiperSlide>
      
      </Swiper> */}
    
      <ChangeBanner/>
 
      <div data-aos="fade-up">
      <div className={`container ${style.aboutMe}`}>
        <p>MUHAMED RUSHITI IS A PROFESSIONAL PHOTOGRAPHER + CREATIVE DIRECTOR BASED IN PRISTINA, KOSOVO.</p>
        <Link  to="/about"  onClick={() => {
                            window.scrollTo(0, 0);
                      
                        }}>Read More</Link>
      </div>
      </div>

      <Category image="/images/comercial/pan46.webp" category="COMERCIAL WORK" color="withColor" position="changePos" link="/comercail"/>
      <div className={style.comercialDis}>
           {comercial.map((item,index)=>{
            return(
              <Category image={item.image} category={item.name} color="withColor" link={item.link} key={index}/>
            )
           })}
      </div>
      <Video/>
      <Slider/>
 
        </div>
    )
}

export default Home;