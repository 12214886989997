import React from "react";
import Portfolio from "../../components/portfolio/Portfolio";
import Slider from "../../components/slider/slider";


const Lifestyle = () =>{
  const collage = [
    "/images/modeling/model49.webp",
    "/images/modeling/model3.webp",
    "/images/modeling/model6.webp",
    "/images/modeling/model7.webp",
    "/images/modeling/model8.webp",
    "/images/modeling/model10.webp",
    "/images/modeling/model11.webp",
    "/images/modeling/model12.webp",
    "/images/modeling/model13.webp",
    "/images/modeling/model14.webp",
    "/images/modeling/model15.webp",
    "/images/modeling/model16.webp",
    "/images/modeling/model17.webp",
    "/images/modeling/model19.webp",
    "/images/modeling/model20.webp",
    "/images/modeling/model21.webp",
    "/images/modeling/model22.webp",
    "/images/modeling/model23.webp",
    "/images/modeling/model24.webp",
    "/images/modeling/model25.webp",
    "/images/modeling/model26.webp",
    "/images/modeling/model32.webp",
    "/images/modeling/model33.webp",
    "/images/modeling/model34.webp",
    "/images/modeling/model35.webp",
    "/images/modeling/model38.webp",
    "/images/modeling/model39.webp",
    "/images/modeling/model40.webp",
    "/images/modeling/model41.webp",
    "/images/modeling/model43.webp",
    "/images/modeling/model44.webp",
    "/images/modeling/model47.webp"
];


      return(
          <>
        
              <Portfolio collage={collage}  title="LIFESTYLE" text="Exploring the essence of everyday moments, I specialize in capturing authentic and vibrant photographs that reflect your unique lifestyle. Let's create images that resonate with your personality and tell your story beautifully."/>
              <Slider/>
              </>
      )
  }
  

export default Lifestyle;